import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import AuthenticationMixin from "@/mixins/AuthenticationMixin";

export default {
  mixins: [AuthenticationMixin],
  data() {
    return {
      loadingAttendanceListTemplateObjects: [],
    };
  },
  watch: {},
  computed: {
    ...mapFields('attendanceListTemplate', {
      attendanceListTemplatesById: 'byId',
      attendanceListTemplateIds: 'allIds',
      attendanceListTemplateIdsCached: "cacheAllIds",
    }),
    ...mapGetters('attendanceListTemplate', {
      attendanceListTemplateItems: 'list',
      findAttendanceListTemplate: 'find'
    }),
  },
  methods: {
    ...mapActions('attendanceListTemplate', {
      retrieveAttendanceListTemplates: 'load',
      fetchAllAttendanceListTemplates: 'fetchAll',
    }),
  }
};
